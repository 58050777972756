import React from 'react';
import {observer} from "mobx-react-lite";
import BasketItem from "./BasketItem";
import {editBasket} from "../https/productAPI";
import {Row} from "react-bootstrap";

const BasketList = observer(({basket, setBasket, mart}) => {

    const addBasketProduct = (data) => {
        data.quantity = data.quantity + 1
        editBasket(data).then(result => {
            setBasket(result)
        })
    }

    const removeBasketProduct = (data) => {
        data.quantity = data.quantity - 1
        editBasket(data).then(result => {
            setBasket(result)
        })
    }

    return (
        <>
            <p>Список товаров</p>
            <Row>
            {basket.map((item, index) => item.product.martId === mart.id && item.quantity > 0 &&
                <BasketItem
                    key={index}
                    product={item}
                    addBasketProduct={addBasketProduct}
                    removeBasketProduct={removeBasketProduct}
                />
            )}
            </Row>
        </>
    );
});

export default BasketList;