import React, {useContext, useEffect, useState} from 'react';
import {Container} from "react-bootstrap";
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import AddressList from "../components/AddressList";
import AddressAdd from "../components/AddressAdd";

const Address = observer(() => {
    const {user} = useContext(Context)
    const [addresses, setAddresses] = useState(user.addresses);
    const [currentAddress, setCurrentAddress] = useState(user.user?.mainAddress);
    const [addressesCount, setAddressesCount] = useState(user.addressesCount);

    return (
        <Container>
            <AddressList addresses={addresses} setAddresses={setAddresses} addressesCount={addressesCount}
                         setAddressesCount={setAddressesCount} currentAddress={currentAddress}
                         setCurrentAddress={setCurrentAddress}/>
            <AddressAdd setAddresses={setAddresses} setAddressesCount={setAddressesCount}
                        currentAddress={currentAddress}
                        setCurrentAddress={setCurrentAddress}/>
        </Container>
    );
});

export default Address;