import React, {useContext} from 'react';
import {Col, Figure, Row} from "react-bootstrap";
import {Context} from "../index";
import {observer} from "mobx-react-lite";

const ShopMartList = observer(() => {
    const {products} = useContext(Context)
    return (
        <Row className={'row-cols-2 p-0'}>
            {products.marts.map(mart =>
                <Col
                    key={mart.id}
                    onClick = {() =>{
                        products.setSelectedMart(mart);
                    }}
                >
                    <Figure>
                        <Figure.Image src="/images/cat_img.jpg" />
                        <Figure.Caption>
                            {mart.name}
                        </Figure.Caption>
                    </Figure>
                </Col>
            )}
        </Row>
    );
});

export default ShopMartList;