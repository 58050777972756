import React from 'react';
import {Row} from "react-bootstrap";
import BasketMartBarItem from "./BasketMartBarItem";

const BasketMartBar = ({basket, mart, setMart, martList}) => {
    return (
        <>
            <Row className="overflow-x-auto flex-nowrap">
                {martList.map((item, index) =>
                    <BasketMartBarItem basket={basket} mart={item} setMart={setMart} key={index} variant={mart.id === item.id ? 'primary':'outline-secondary'}/>
                )}
            </Row>
        </>
    );
};

export default BasketMartBar;