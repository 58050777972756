import React, {useContext, useEffect, useState} from 'react';
import {Col, Figure, Badge} from "react-bootstrap";
import {useNavigate} from "react-router-dom"
import {Context} from "../index";


const ShopCategoriesListItem = ({category}) => {
    const {products} = useContext(Context)
    const navigate = useNavigate();
    let [counts, setCounts] = useState(0);

    useEffect(() => {
        let categoryChildren = []
        let tempCount = 0
        if (products.basket.filter(item => (item.quantity > 0)&&(item.product.martId === products.selectedMart.id)).length > 0){
            products.categories.map(item => (item.parent === category.id) && (categoryChildren.push(item.id)))
            products.basket.map(basketItem => categoryChildren.includes(basketItem.product.categoryId) && (tempCount += basketItem.quantity))
            setCounts(tempCount)
        }
    }, [products.basket]);

    return (
        <div>
            <Col
                className={'position-relative'}
                key={category.id}
                onClick={() => {
                    products.setSelectedCategory(category);
                    navigate('/product/' + category.id);
                }}
            >
                <Badge bg="secondary" className={'position-absolute top-0 end-0'}>
                    {counts}
                </Badge>
                <Figure>
                    <Figure.Image src="/images/cat_img.jpg"/>
                    <Figure.Caption>
                        {category.name}
                    </Figure.Caption>
                </Figure>
            </Col>
        </div>
    );
};

export default ShopCategoriesListItem;