import React, {useContext, useEffect, useState} from 'react';
import {Button, Form, Offcanvas} from "react-bootstrap";
import {createAddresses, fetchUser} from "../https/userAPI";
import {observer} from "mobx-react-lite";
import {Context} from "../index";

const AddressAdd = observer(({
                                 setAddresses,
                                 setAddressesCount,
                                 currentAddress,
                                 setCurrentAddress
                             }) => {
    const {user} = useContext(Context)
    const [addAddress, setAddAddress] = useState(false);
    const [street, setStreet] = useState('')
    const [apartment, setApartment] = useState('')
    const [number, setNumber] = useState('')
    const [entrance, setEntrance] = useState('')

    useEffect(() => {
        if (addAddress) {
            setStreet('')
            setApartment('')
            setNumber('')
            setEntrance('')
        }
    }, [addAddress]);

    const add = () => {
        createAddresses({street: street, apartment: apartment, number: number, entrance: entrance})
            .then(data => {
                user.setAddresses(data.data)
                setAddresses(data.data)
                user.setAddressesCount(data.count)
                setAddressesCount(data.count)
                setAddAddress(false)
                fetchUser()
                    .then(data => {
                        user.setUser(data);
                        setCurrentAddress(data.mainAddress)
                    })
            })
            .then(response => console.log(response))
            .catch(error => alert(error.response.data.error));
    }

    return (
        <>
            <Button onClick={() => setAddAddress(true)}>+ Добавить адрес</Button>

            <Offcanvas
                show={addAddress}
                onHide={() => setAddAddress(false)}
                placement={'bottom'}
                className={'h-75'}
            >
                <Offcanvas.Body>
                    <h4>Добавление адреса</h4>
                    <Form>
                        <Form.Control
                            placeholder="Введите название улицы"
                            value={street}
                            type={'text'}
                            onChange={e => setStreet(e.target.value)}
                            className={'mt-2'}
                            required
                        />
                        <Form.Control
                            placeholder="Введите номер дома"
                            type={'text'}
                            value={apartment}
                            onChange={e => setApartment(e.target.value)}
                            className={'mt-2'}
                            required
                        />
                        <Form.Control
                            placeholder="Введите номер квартиры"
                            type={'number'}
                            value={number}
                            onChange={e => setNumber(e.target.value)}
                            className={'mt-2'}
                            required
                        />
                        <Form.Control
                            placeholder="Введите номер подъезда"
                            value={entrance}
                            onChange={e => setEntrance(e.target.value)}
                            type={'number'}
                            className={'mt-2'}
                            required
                        />
                        <Button className={'mt-2'} onClick={add}>Сохранить</Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
});

export default AddressAdd;