import React from 'react';
import {Container} from "react-bootstrap";
import ProductCategoryBar from "../components/ProductCategoryBar";
import ProductList from "../components/ProductList";
import BasketBar from "../components/BasketBar";

const ProductPage = () => {
    return (
        <Container>
            <ProductCategoryBar/>
            <ProductList/>
            <BasketBar/>
        </Container>
    );
};

export default ProductPage;