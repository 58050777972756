import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Button, Container} from "react-bootstrap";
import BasketList from "../components/BasketList";
import BasketAddressSelect from "../components/BasketAddressSelect";
import BasketMartBar from "../components/BasketMartBar";
import {Context} from "../index";
import BasketDelivery from "../components/BasketDelivery";

const Basket = observer(() => {
    const {user} = useContext(Context)
    const {products} = useContext(Context)
    const [address, setAddress] = useState(user.user.mainAddress)
    const [basket, setBasket] = useState(products.basket)
    const [mart, setMart] = useState(products.selectedMart)
    const [martList, setMartList] = useState([])
    const [totalPrice, setTotalPrice] = React.useState(0);

    useEffect(() => {
        products.setBasket(basket)
        if (basket.some(item => item.quantity > 0 && item.product.martId === mart.id) === false && martList.length > 1) {
            setMart(products.marts.filter(item => basket.some(basketItem => basketItem.quantity > 0 && basketItem.product.martId === item.id))[0])
        }
    }, [basket]);

    useEffect(() => {
        let sum = 0
        basket.filter(basketItem => basketItem.product.martId === mart.id && basketItem.quantity > 0 && (sum = sum + basketItem.product.price * basketItem.quantity));
        setTotalPrice(sum)
        setMartList(products.marts.filter(item => basket.some(basketItem => basketItem.quantity > 0 && basketItem.product.martId === item.id)))
    }, [basket, mart]);

    useEffect(() => {
        products.setSelectedMart(mart)
    }, [mart]);

    return (
        <Container>
            {martList.length > 0 ?
                <>
                    <BasketMartBar basket={basket} mart={mart} setMart={setMart} martList={martList}/>
                    <BasketList basket={basket} setBasket={setBasket} mart={mart}/>
                    <BasketAddressSelect address={address} setAddress={setAddress}/>
                    <BasketDelivery/>
                    <Button> {totalPrice} | К оформлению</Button>
                </>
                :
                <p>Корзина пуста</p>
            }

        </Container>
    );
});

export default Basket;