import React from 'react';
import {ButtonGroup, Card, Col, Row, ToggleButton} from "react-bootstrap";
import {observer} from "mobx-react-lite";

const BasketItem = observer(({product, addBasketProduct, removeBasketProduct}) => {

    const addBasketProductHandler = (data) => {
        addBasketProduct(data);
    }

    const removeBasketProductHandler = (data) => {
        removeBasketProduct(data);
    }

    return (
        <Card className={'mb-2'}>
            <Row className={'g-0'}>
                <Col className={'col-4'}>
                    <img className={'img-fluid rounded-start'} src={"/images/cat_img.jpg"} alt={'...'}/>
                </Col>
                <Col className={'col-8'}>
                    <Card.Body>
                        <Card.Text>{product.product.name}</Card.Text>
                        <Card.Text>{product.product.weight} {product.product.measure}</Card.Text>
                    </Card.Body>
                    {product.quantity > 0 ?
                        <>
                            <ButtonGroup>
                                <ToggleButton type={'checkbox'} variant={'success'}
                                              onClick={() => removeBasketProductHandler(product)}>-</ToggleButton>
                                <ToggleButton type={'checkbox'}
                                              variant={'success'}>{product.quantity} шт.</ToggleButton>
                                <ToggleButton type={'checkbox'}
                                              variant={'success'}>{product.quantity * product.product.price}</ToggleButton>
                                <ToggleButton type={'checkbox'} variant={'success'}
                                              onClick={() => addBasketProductHandler(product)}>+</ToggleButton>
                            </ButtonGroup>
                        </>
                        :
                        <ButtonGroup>
                            <ToggleButton type={'checkbox'}
                                          variant={'light'}>{product.product.price}</ToggleButton>
                            <ToggleButton type={'checkbox'} variant={'light'}
                                          onClick={() => addBasketProductHandler({
                                              productId: product.product.id,
                                              quantity: 0
                                          })}>+</ToggleButton>
                        </ButtonGroup>
                    }
                </Col>
            </Row>
        </Card>
    );
});

export default BasketItem;