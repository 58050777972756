import React, {useContext, useEffect, useState} from 'react';
import {Button, Col, Form, ListGroup, Offcanvas, Row} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import {deleteAddress, fetchUser, updateAddress, updateUser} from "../https/userAPI";
import {Context} from "../index";

const AddressList = observer(({
                                  addresses,
                                  setAddresses,
                                  addressesCount,
                                  setAddressesCount,
                                  currentAddress,
                                  setCurrentAddress
                              }) => {
    const {user} = useContext(Context)

    const currentAddressHandler = (address) => {
        setCurrentAddress(address)
        updateUser({"mainAddress": address.id}).then(data => {
            user.setUser(data)
        })
    }

    const [editAddress, setEditAddress] = useState(false);
    const [street, setStreet] = useState('')
    const [apartment, setApartment] = useState('')
    const [number, setNumber] = useState('')
    const [entrance, setEntrance] = useState('')
    const [selectedAddress, setSelectedAddress] = useState('')

    const editAddressHandler = (address) => {
        setSelectedAddress(address.id)
        setStreet(address.street)
        setApartment(address.apartment)
        setNumber(address.number)
        setEntrance(address.entrance)
        setEditAddress(true)
    }

    const edit = () => {
        updateAddress({
            'id': selectedAddress,
            'street': street,
            'apartment': apartment,
            'number': number,
            'entrance': entrance
        }).then(data => {
                user.setAddresses(data.data)
                setAddresses(data.data)
                user.setAddressesCount(data.count)
                setAddressesCount(data.count)
                setEditAddress(false)
                fetchUser()
                    .then(data => {
                        user.setUser(data);
                        setCurrentAddress(data.mainAddress)
                    })
            }
        )
    }

    const deleteAddressHandler = (address) => {
        deleteAddress(selectedAddress).then(data => {
            user.setAddresses(data.data)
            setAddresses(data.data)
            user.setAddressesCount(data.count)
            setAddressesCount(data.count)
            fetchUser()
                .then(data => {
                    user.setUser(data);
                    setCurrentAddress(data.mainAddress)
                })
            setEditAddress(false)
        })
    }

    return (
        <>
            <ListGroup variant="flush">
                {addressesCount === 0 &&
                    <h4>Чтобы добавить адрес нажмите кнопку ниже</h4>
                }

                {addressesCount !== 0 && addresses.map(address =>
                    <ListGroup.Item key={address.id}>
                        <Row>
                            <Col className={'col-10'} onClick={() => editAddressHandler(address)}>
                                <Row>
                                    <h4>{address.street}, д. {address.apartment}</h4>
                                </Row>
                                <Row>
                                    <h6>кв. {address.number}, подъезд {address.entrance}</h6>
                                </Row>
                            </Col>
                            <Col className={'col-2 d-flex align-items-center'}>
                                <Form.Check
                                    type={'radio'}
                                    name="current address"
                                    id={address.id}
                                    onChange={() => currentAddressHandler(address)}
                                    checked={address.id === currentAddress?.id}
                                />
                            </Col>
                        </Row>
                    </ListGroup.Item>
                )}
            </ListGroup>

            <Offcanvas
                show={editAddress}
                onHide={() => setEditAddress(false)}
                placement={'bottom'}
                className={'h-75'}
            >
                <Offcanvas.Body>
                    <h4>Изменение адреса</h4>
                    <Form>
                        <Form.Control
                            placeholder="Введите название улицы"
                            value={street}
                            type={'text'}
                            onChange={e => setStreet(e.target.value)}
                            className={'mt-2'}
                            required
                        />
                        <Form.Control
                            placeholder="Введите номер дома"
                            type={'text'}
                            value={apartment}
                            onChange={e => setApartment(e.target.value)}
                            className={'mt-2'}
                            required
                        />
                        <Form.Control
                            placeholder="Введите номер квартиры"
                            type={'text'}
                            value={number}
                            onChange={e => setNumber(e.target.value)}
                            className={'mt-2'}
                            required
                        />
                        <Form.Control
                            placeholder="Введите номер подъезда"
                            value={entrance}
                            onChange={e => setEntrance(e.target.value)}
                            type={'text'}
                            className={'mt-2'}
                            required
                        />
                        <Button className={'mt-2'} onClick={deleteAddressHandler}>Удалить</Button>
                        <Button className={'mt-2'} onClick={edit}>Сохранить</Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
});

export default AddressList;