import React, {useContext, useEffect, useState} from 'react';
import {Button, Col, Form, ListGroup, Offcanvas, Row} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {createAddresses, fetchUser} from "../https/userAPI";

const BasketAddressSelect = observer(({address, setAddress}) => {
    const {user} = useContext(Context)
    const [addressAdd, setAddressAdd] = useState(false)
    const [addressList, setAddressList] = useState(false)

    const [street, setStreet] = useState('')
    const [apartment, setApartment] = useState('')
    const [number, setNumber] = useState('')
    const [entrance, setEntrance] = useState('')

    useEffect(() => {
        if (!addressAdd) {
            setStreet('')
            setApartment('')
            setNumber('')
            setEntrance('')
        }
    }, [addressAdd]);

    const addAddress = () => {
        createAddresses({street: street, apartment: apartment, number: number, entrance: entrance}).then(data => {
            user.setAddresses(data.data)
            user.setAddressesCount(data.count)
            setAddress(data.data[data.data.length-1])
            fetchUser()
                .then(data => {
                    user.setUser(data);
                })
        })
        setAddressAdd(false)
        setAddressList(false)
    }

    return (
        <>
            <Row className={'pt-3 pb-3'}>
                {address !== null ?
                    <Col>
                        <Row>ул. {address.street}, д. {address.apartment}</Row>
                        <Row>кв. {address.number}, подъезд {address.entrance}</Row>
                    </Col>
                    :
                    <Col className={'align-items-center'}>Выберите адрес</Col>
                }
                <Col className={'align-items-center text-end'}><Button
                    onClick={() => setAddressList(true)}>Изменить</Button></Col>
            </Row>
            <Offcanvas
                show={addressList}
                onHide={() => setAddressList(false)}
                placement={'bottom'}
                className={'h-75'}
            >
                <Offcanvas.Body>
                    <ListGroup>
                        {user.addresses.map(item =>
                            <ListGroup.Item onClick={() => {
                                setAddress(item)
                                setAddressList(false)
                            }} className={'pb-3'} key={item.id}>ул. {item.street}, д. {item.apartment},
                                кв. {item.number}, подъезд {item.entrance}</ListGroup.Item>
                        )}
                    </ListGroup>

                    <Button onClick={() => {
                        setAddressAdd(true)
                        setAddressList(false)
                    }}>Добавить адрес</Button>
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas
                show={addressAdd}
                onHide={() => {
                    setAddressList(true)
                    setAddressAdd(false)
                }}
                placement={'bottom'}
                className={'h-50'}
            >
                <Offcanvas.Body>
                    <h4>Добавление адреса</h4>
                    <Form>
                        <Form.Control
                            placeholder="Введите название улицы"
                            value={street}
                            type={'text'}
                            onChange={e => setStreet(e.target.value)}
                            className={'mt-2'}
                            required
                        />
                        <Form.Control
                            placeholder="Введите номер дома"
                            type={'text'}
                            value={apartment}
                            onChange={e => setApartment(e.target.value)}
                            className={'mt-2'}
                            required
                        />
                        <Form.Control
                            placeholder="Введите номер квартиры"
                            type={'number'}
                            value={number}
                            onChange={e => setNumber(e.target.value)}
                            className={'mt-2'}
                            required
                        />
                        <Form.Control
                            placeholder="Введите номер подъезда"
                            value={entrance}
                            onChange={e => setEntrance(e.target.value)}
                            type={'number'}
                            className={'mt-2'}
                            required
                        />
                        <Button onClick={addAddress}>Добавить адрес</Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
        ;
});

export default BasketAddressSelect;