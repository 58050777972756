import React from 'react';
import {Col, Row} from "react-bootstrap";

const BasketDelivery = () => {
    return (
        <>
            <Row>
                <Col>
                    Стоимость доставки:<br/>
                    Платная доставка 120 Р
                </Col>
            </Row>
            <Row>
                <Col>
                    средне время доставки<br/>
                    от 60 мин
                </Col>
            </Row>
        </>
    );
};

export default BasketDelivery;