import React from 'react';
import {Button, Container} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";

const ShopAddressBar = observer(() => {
    const navigate = useNavigate();
    return (
        <Container>
            <h6>Добавьте номер телефона и адрес для расчета времени и стоимости доставки</h6>
            <Button onClick={() => navigate('/address')}>Изменить данные</Button>
        </Container>
    );
});

export default ShopAddressBar;