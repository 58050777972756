import React, {useEffect} from 'react';
import {Button} from "react-bootstrap";
import {observer} from "mobx-react-lite";

const BasketMartBarItem = observer(({basket, mart, setMart,variant}) => {
    const [totalPrice, setTotalPrice] = React.useState(0);

    useEffect(() => {
        let sum = 0
        basket.filter(basketItem => basketItem.product.martId === mart.id && basketItem.quantity > 0 && (sum = sum + basketItem.product.price * basketItem.quantity));
        setTotalPrice(sum)
    }, [basket, mart.id]);

    return (
        <Button
            style={{cursor: "pointer"}}
            key={mart.id}
            className="col-4 m-1"
            variant={variant}
            onClick={() => setMart(mart)}
        >
            {mart.name}<br/>
            {totalPrice} р
        </Button>
    );
});

export default BasketMartBarItem;