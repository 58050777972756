import React, {useContext} from 'react';
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import {Row} from "react-bootstrap";
import ShopCategoriesListItem from "./ShopCategoriesListItem";

const ShopCategoriesList = observer(() => {
    const {products} = useContext(Context)

    return (
        <Row className={'row-cols-3 p-0'}>
            {products.categories.map(category => category.parent === 0 && category.martId === products.selectedMart.id &&
                <ShopCategoriesListItem key={category.id} category={category} />
            )}
        </Row>
    );
});

export default ShopCategoriesList;